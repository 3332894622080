
import { observer } from 'mobx-react-lite';import * as React from "react";
import { Menu, MenuItem, MenuSelectEvent } from "@progress/kendo-react-layout";
import { Drawer, DrawerContent, DrawerItemProps, DrawerSelectEvent } from '@progress/kendo-react-layout';
import { withRouter } from 'react-router-dom';
import { LocalizationService, useLocalization } from '@progress/kendo-react-intl';

import { Header } from './Header';
import { useServices } from 'src/services';
import d4urLogo from "../assets/images/Ford_D4URC_Blue_Screen_RGB_v2.png";
import {useState} from "react";
import {MouseEventHandler} from "react";
import {useEffect} from "react";

const MenuNavContainer = (props: any) => {
    const onSelect = (event: MenuSelectEvent) => {
        props.history.push(event.item.data.route);
    };
    const { history, location, children } = props;
    const { d4ur } = useServices();
    const items: DrawerItemProps[] = [
        { name: 'myEvents', icon: 'k-i-cloud', selected: true , route: '/events' },
        { name: 'myAccount', icon: 'k-i-user', route: '/profile' },

        // { name: 'dashboard', icon: 'k-i-grid', selected: true , route: '/dashboard' },
        // { name: 'planning', icon: 'k-i-calendar', route: '/planning' },
        { separator: true },
        // { name: 'help', icon: 'k-i-help', route: '/dashboard' },
        { name: 'contact', icon: 'k-i-email', route: '/contact' },
        { separator: true },
        { name: 'logout', icon: 'k-i-logout', onClick:async () => {
                console.log('log out');
                await d4ur.doLogout();
                history.push('/');
            } },
    ];
    const [ isSmallerScreen, setIsSmallerScreen ] = useState<boolean>(window.innerWidth < 1024);
    const [ expanded, setExpanded ] = useState<boolean>(!isSmallerScreen);

    const resizeWindow = () => {
        setIsSmallerScreen(window.innerWidth < 1024);
    }

    const handleClick:MouseEventHandler = () => {
        setExpanded(!expanded);
    }

    const handleSelect = (e:DrawerSelectEvent) => {
        setExpanded(!isSmallerScreen);
        history.push(e.itemTarget.props.route);
    }

    const getSelectedItem = (pathName:string) => {
        let currentPath = items.find(item => item.route === pathName);
        if (currentPath?.name) {
            return currentPath.name;
        }
    }
    useEffect(() => {
        window.addEventListener('resize', resizeWindow, false)
        resizeWindow();
        return () => {
            window.removeEventListener('resize', resizeWindow)
        }
    });

    let selected = getSelectedItem(location.pathname);
    const localizationService:LocalizationService = useLocalization();

    if (isSmallerScreen) {
        return (
            <React.Fragment>
                <Header
                    onButtonClick={handleClick}
                    history={history}
                    page={localizationService.toLanguageString(`custom.${selected}`, 'CarePenguin')}
                />
                <Drawer
                    expanded={expanded}
                    animation={{duration: 100}}
                    items={items.map((item) => ({
                        ...item,
                        text: localizationService.toLanguageString(`custom.${item.name}`, item.name),
                        selected: item.name === selected
                    }))
                    }
                    position='start'
                    mode={isSmallerScreen ? 'overlay' : 'push'}
                    mini={isSmallerScreen ? false : true}

                    onOverlayClick={handleClick}
                    onSelect={handleSelect}
                >
                    <DrawerContent>
                        {children}
                    </DrawerContent>
                </Drawer>
            </React.Fragment>
        );
    }

    return (
        <>
            <div style={{display: 'flex'}}  className='menu_items_container'>
                <Menu onSelect={onSelect} className='nav-menu'>
                    <MenuItem cssStyle={{color: '#393939'}} text="My Events" data={{ route: "/events" }} />
                    <MenuItem cssStyle={{color: '#393939'}} text="My Account" data={{ route: "/profile" }} />
                    {/* <MenuItem cssStyle={{color: '#393939'}} text="Help" data={{ route: "/dashboard" }}/> */}
                    <MenuItem cssStyle={{color: '#393939'}} text="Contact" data={{ route: "/contact" }}/>
                </Menu>
                <div className='img_container'>
                    <img src={d4urLogo} alt="Ford Drive 4 UR School Logo" onClick={() => history.push('/events')} />

                </div>
                    <p onClick={async () => {
                        console.log('log out');
                        await d4ur.doLogout();
                        history.push('/');
                    }}>
                        Log Out
                    </p>
            </div>

            <div style={{ padding: 10 }}>{props.children}</div>
        </>
    );
};
export default withRouter(observer(MenuNavContainer));

