import { default as React } from "react";
// @ts-ignore
import PrivacyBanner from 'react-ford-ccpa-banner';
import { useLocalization } from "@progress/kendo-react-intl";
import logo from "../../assets/images/Ford_D4URC_Blue_Screen_RGB_v2.png";
import { useHistory } from "react-router-dom";
import { faqData } from "../../resources/faqs";

const FAQScreen = () => {
    const localizationService = useLocalization();
    const { language } = localizationService;
    let history = useHistory();

    const renderFAQSection = (category: string, title: string) => {
        return (
            <>
                <h3>{title}</h3>
                {faqData
                    .filter(faq => faq.category === category)
                    .map((faq, index) => (
                        <li key={index}>
                            <span className="bold">{faq.question}</span>
                            <p>{faq.answer}</p>
                        </li>
                    ))}
                <br/>
            </>
        );
    };

    return (
        <div id="Profile" className="guidelines-page main-content">
            <img src={logo} alt={'Ford Drive 4 UR Community Logo'} className={'logo'} onClick={() => {
                                        history.push("/resources");
                                    }} />
            <div className="faq_container">
                <div className="card-component">
                <h2>Frequently Asked Questions</h2>
                    <ul>
                        {renderFAQSection('general', 'General Questions')}
                        {renderFAQSection('dealer', 'Dealer Questions')}
                        {renderFAQSection('electronic', 'Electronic Waiver Questions')}
                        <li className='asterisk_li'>NO PURCHASE NECESSARY. Must be 18 years of age or older with a valid driver's license and valid automobile insurance. Donation of $20 per valid test-drive, up to 300 test-drives, for a total donation of $6,000 per event. Limit one (1) donation per household. If the benefitting organization does not cash their donation check within 60 days of issue, Ford reserves the right to donate the funds to another organization of their choice.
                        </li>
                    </ul>
                    <br/>
                </div>
            </div>
            <PrivacyBanner
                containerStyle={{ padding: '10px 0 0', width: '100%' }}
                privacyContainerStyle={{ padding: '10px 0', width: "60%", marginLeft: '20%' }}
                showPrivacy={true}
                language={language}
                team={'ford'}
                imgStyle={{ paddingLeft: '30px' }}
                otherLinks={[
                    { text: "CONTACT US", url: "/contact", target: '_blank' },
                    { text: "PROGRAM TERMS AND CONDITIONS", url: "/terms-eligibility", target: '_blank'},
                    { text: "PROGRAM RESOURCES ", url: "/resources", target: '_blank' },
                ]}
            />
        </div>
    );
};

export default FAQScreen;
