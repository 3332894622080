import { default as React, useEffect, useState } from "react";
import { useLocalization } from "@progress/kendo-react-intl";
import { useHistory } from "react-router";
import { useServices } from "src/services";
import { useStores } from "src/stores";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from '@progress/kendo-react-indicators';
import banner from "../assets/images/2025_D4URC_Dealer_Portal_Landing_Page_66825.png";
import logo from "../assets/images/Ford_D4URC_Blue_Screen_RGB_v2.png";
import PrivacyBanner from "react-ford-ccpa-banner";
import '../styles/_home.scss';

const HomeScreen = () => {
  const localizationService = useLocalization();
  const { language } = localizationService;
  const history = useHistory();
  const { d4ur } = useServices();
  const { ui } = useStores();
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setLoading(true);
    d4ur.checkWaitlist().then((res) => {
      if (res.result && res.result.events_full === 1) {
        ui.setEventsFull(true);
      }
      setLoading(false);
    })
  }, [ui.eventsFull, d4ur, ui]);

  return (
    <>
      <div id="Home" className="home-page main-content">
        <div className="content">
          {/* <img src={logo} alt={"Ford Drive 4 UR School Logo"} className={"logo"} /> */}
          {/* <h1 className="home-title">Drive 4 UR School</h1> */}
          <div className='splash_header'>
            <p>
              Take the wheel. <span>Help your community.</span>
            </p>
          </div>
          <div className='img_container'>
            <img src={banner} alt={"Group of volunteers posing in front of Ford vehicles."} className="banner_img" />
          </div>
          <div className='body_container'>
            <div className='inner_container'>
              <div className='text_container'>
                {loading ? (
                  <>
                    <p>Checking waitlist status...</p>
                    <div style={{ textAlign: 'center' }}><Loader type="infinite-spinner" size="large" style={{color: '#066FEF'}} /></div>
                  </>
                  ) :
                  !ui.eventsFull ? (
                    <div style={{ fontFamily: 'FordF1-Light' }}>
                      <p>
                      From August 22nd-November 30th, eligible Ford dealers may host a one day test-drive event to benefit their local organizations.
                      </p>
                      <p>
                      This website will be your hub for all things related to your Drive 4 UR Community event. If you have not requested your event, you will do so after you create or log into your dealer account. Any dealership may create an account. Event space is limited and available on a first come, first serve basis.
                      </p>
                      <span>All dealers must create a new account to participate in this year’s Drive 4 UR Community program, which will allow you to request an event.</span>
                    </div>
                  ) : (
                    <>
                      {/* <p className='waitlist_text'>Thank you for visiting the Drive 4 Ur Community enrollment portal. The 2025 D4URC fall session is now closed. Questions? Please contact <a href="hell@d4urc.com" style={{ cursor: 'pointer', color: '#066FEF'}}>hello@d4urc.com</a>.</p> */}
                      {/* <p className='waitlist_text'>Events are currently sold out. If you already have an account, you may log in and join the waitlist. If you do not have an account, please create one to join the waitlist.</p> */}
                      <p className='waitlist_text'>The enrollment period has not yet opened for this season. Please check back soon.</p>
                    </>
                  )
                }
              </div>
              <img src={logo} alt={"Ford Drive 4 UR Community Logo"} className={"logo"} />
            </div>
            {/* <p>
              The Enrollment Period Has Closed.
            </p> */}
            <div className="button-group">
              <Button
                className="home_screen_btn home_screen_btn_left"
                // disabled={true}
                onClick={() => {
                  history.push("/login");
                }}
              >
                {localizationService.toLanguageString("custom.logIn")}
              </Button>
              <Button
                className="home_screen_btn"
                // disabled={true}
                onClick={() => {
                  history.push("/signup");
                }}
              >
                {localizationService.toLanguageString("custom.signUp")}
              </Button>
              <Button
                className="home_screen_btn"
                onClick={() => {
                  history.push("/password");
                }}
              >
                {localizationService.toLanguageString("custom.forgotPassword")}
              </Button>
            </div>

          </div>
        </div>
      </div>
      <PrivacyBanner
        containerStyle={{ padding: "10px 0 0" }}
        privacyContainerStyle={{ padding: "10px 0", width: "60%", marginLeft: '20%' }}
        showPrivacy={true}
        language={language}
        team={'ford'}
        imgStyle={{ paddingLeft: "30px" }}
        otherLinks={[
          { text: "CONTACT US", url: "/contact", target: '_blank' },
          { text: "PROGRAM TERMS AND CONDITIONS", url: "/terms-eligibility", target: '_blank'},
          { text: "PROGRAM RESOURCES ", url: "/resources", target: '_blank' },
      ]}
      />
    </>
  );
};

export default HomeScreen;
