import React, { useState } from 'react';

import { useLocalization } from '@progress/kendo-react-intl';
import {useHistory, useParams} from "react-router-dom";
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { Error } from "@progress/kendo-react-labels";
import { passwordValidator, passwordMatchValidator } from '../validators';
import logo from "../assets/images/Ford_D4URC_Blue_Screen_RGB_v2.png";
// @ts-ignore
import PrivacyBanner from 'react-ford-ccpa-banner';
import { AppContext } from '../AppContext'
import { useServices } from 'src/services';
import { Input } from 'src/components/form/Input';
import sideImage from "../assets/images/2025_D4URC_Dealer_Portal_Forgot_Password_Page_66836.png";
import '../styles/_login.scss';


const ResetPasswordScreen = () => {
        const {...formValues} = React.useContext(AppContext);
        const localizationService = useLocalization();
        const history = useHistory();
        const { language } = localizationService;
        const [ passwordError, setPasswordError ] = useState<string>();
        const [ confirmMessage, setConfirmMessage ] = useState<string>('');
        const { d4ur } = useServices();
        // @ts-ignore
        const { token } = useParams();

        const onSubmit = React.useCallback(
            async (dataItem) => {
                if (!token) {
                    setPasswordError('No token value found');
                }

                const call:any = await d4ur.doResetPassword(dataItem.password, token);
                if (call.result.error) {
                    console.log('[ResetPasswordScreen]', 'Password Error');
                    setPasswordError(call.result.error);
                }
                if (call.result.success) {
                    console.log('[ResetPasswordScreen]', 'email sent');
                    setConfirmMessage(localizationService.toLanguageString('custom.passwordReset', 'Password reset'))
                }
            },
            [d4ur, token, localizationService]
        );

        return (
            <div id="ForgotPassword" className="forgot_pass">
            <div className='main'>
                <div className="left_content">
                    <img src={logo} alt={'Ford Drive 4 UR School Logo'} className='logo' onClick={() => {
                                        history.push("/");
                                    }}/>
                <div className="form-container" style={{ maxWidth: 700 }}>
                        <h1 className="form_title">{localizationService.toLanguageString('custom.resetPassword', 'Reset Password')}</h1>
                        <Form
                            onSubmit={onSubmit}
                            initialValues={{
                                ...formValues,
                                password: '',
                                password2: '',
                            }}
                            render={(formRenderProps) => (
                                <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                                    { passwordError && <Error>{passwordError}</Error> }

                                    <Field
                                        id={'password'}
                                        name={'password'}
                                        autoComplete="new-password"
                                        type={'password'}
                                        label={localizationService.toLanguageString('custom.newPassword', 'New Password')}
                                        validator={passwordValidator}
                                        component={Input}
                                    />

                                    <Field
                                        id={'password2'}
                                        name={'password2'}
                                        autoComplete="new-password"
                                        type={'password'}
                                        label={localizationService.toLanguageString('custom.secondPassword', 'Retype Password')}
                                        validator={passwordMatchValidator}
                                        component={Input}
                                    />
                                    {/* <div className='forgot_pass_container'>
                                            <button className='forgot_pass_btn' onClick={() => {
                                        history.push("/password");
                                    }}>
                                                Forgot Password?
                                            </button>
                                        </div> */}
                                    <div >
                                        <p className='forgot-text' style={{ color: 'green' }}>{confirmMessage && confirmMessage}</p>
                                        <Button
                                            primary={true}
                                            type={'submit'}
                                            disabled={!formRenderProps.allowSubmit}
                                            hidden={!!confirmMessage}
                                            className='log_in_btn'
                                        >
                                            {localizationService.toLanguageString('custom.resetPassword', 'Reset Password')}
                                        </Button>
                                    </div>
                                    <Button
                                        primary={true}
                                        className='log_in_btn'
                                        onClick={() => {
                                            history.push("/");
                                        }}
                                        hidden={!confirmMessage}
                                    >
                                        {localizationService.toLanguageString('custom.goBack', 'Go Back')}
                                    </Button>
                                </FormElement>
                            )}
                        />
                        <div className="reset_container">
                               <p className='sign_up_text'>Don't have an account?</p>
                               <button 
                                    className='sign_up_btn' 
                                    onClick={() => {
                                        history.push("/signup");
                                    }}>
                                    Sign Up
                                </button>
                            </div>
                        <div className="reset_container log_in">
                               <p className='sign_up_text'>Already have an account?</p>
                               <button 
                                    className='sign_up_btn' 
                                    onClick={() => {
                                        history.push("/login");
                                    }}>
                                    Log In
                                </button>
                            </div>
            </div>
                </div>
                <div className='right_content'>
                    <img src={sideImage} alt={'Two volunteers talking next to a Ford vehicle'} className='right_img' />        
                </div>

            </div>
            <PrivacyBanner
                containerStyle={{ padding: '10px 0 0', }}
                privacyContainerStyle={{ padding: '10px 0', width: "60%", marginLeft: '20%' }}
                showPrivacy={true}
                language={language}
                team={'ford'}
                imgStyle={{ paddingLeft: '30px' }}
                otherLinks={[
                    { text: "CONTACT US", url: "/contact", target: '_blank' },
                    { text: "PROGRAM TERMS AND CONDITIONS", url: "/terms-eligibility", target: '_blank'},
                    { text: "PROGRAM RESOURCES ", url: "/resources", target: '_blank' },
                ]}
            />
        </div>
        );
}

export default ResetPasswordScreen;
