import { useLocalization } from "@progress/kendo-react-intl";
import logo from "../assets/images/Ford_D4URC_Blue_Screen_RGB_v2.png";

import * as React from "react";
// @ts-ignore
import PrivacyBanner from 'react-ford-ccpa-banner';
import { useHistory } from "react-router-dom";

const ContactScreen = () => {
    const localizationService = useLocalization();
    const { language } = localizationService;
    let history = useHistory();
    return (
        <div id="Login" className="guidelines-page">
            <div className='min-height'>
                <img src={logo} alt={'Ford Drive 4 UR Community Logo'} className={'logo'} style={{cursor: 'pointer'}} onClick={() => {
                    history.push("/events");
                                        }} />
                    {/* <div className="card-container" style={{ maxWidth: 700 }}> */}

                        {/* <div className="card-component"> */}
                <div className="content contact-content">
                <div className="card-component">
                                <h1 className="card-title">Contact Us</h1>
                                <div>
                                    <div>
                                        <h3>Have a question or need help?</h3>

                                        <p>Contact Drive 4 UR Community Program HQ</p>
                                    </div>
                                    <div>
                                        <p>
                                            <span>Email: </span>
                                            <a href="mailto:hello@d4urc.com">hello@d4urc.com</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                </div>

            </div>
            <PrivacyBanner
                containerStyle={{ padding: '10px 0 0', width: '100%' }}
                privacyContainerStyle={{ padding: '10px 0', width: "60%", marginLeft: '20%' }}
                showPrivacy={true}
                language={language}
                team={'ford'}
                imgStyle={{ paddingLeft: '30px' }}
                otherLinks={[
                    { text: "CONTACT US", url: "/contact", target: '_blank' },
                    { text: "PROGRAM TERMS AND CONDITIONS", url: "/terms-eligibility", target: '_blank'},
                    { text: "PROGRAM RESOURCES ", url: "/resources", target: '_blank' },
                ]}
            />
        </div>
    );
};

export default ContactScreen;
