export const faqData = [
    {
        category: 'general',
        question: "Why did the program name change?",
        answer: "Effective Fall 2025, the Drive 4 UR School program is coming under the Drive 4 UR Community (D4URC) program name. Schools will continue to be eligible as participating beneficiaries, with the added opportunity for dealers to choose a community organization to work with if they desire."
    },
    {
        category: 'general',
        question: "What is Drive 4 UR Community?",
        answer: "D4URC was designed for dealerships to work with any public or private elementary, middle, or high school(s) in addition to any organization in which the funds will directly benefit or go back to the school(s) in any capacity that benefits the students or the community organization’s mission."    },
    {
        category: 'general',
        question: "Where does the event happen?",
        answer: "Program HQ recommends holding events in locations away from the dealership and within the dealership’s Primary Market Area (PMA) or Locality Zone. Holding the event away from the dealership helps establish a low-pressure environment for participants and encourages support from the community. Dealerships are responsible for adhering to all local and state laws regarding off-site test-drives and vehicle displays. Events should be held on one day and at one location only."
    },
    {
        category: 'general',
        question: "How much money can we raise?",
        answer: "Benefiting organizations will earn $20 per household for each valid test-drive at the event with a maximum donation of $6,000.*"
    },
    {
        category: 'general',
        question: "Who can drive?",
        answer: "Anyone 18 years or older with a valid driver’s license and valid automobile insurance can participate. This person does not have to be an organization member in order to qualify."
    },
    {
        category: 'general',
        question: "Which vehicles will be available?",
        answer: "Dealerships are encouraged to bring a full lineup of products with the highest trim level available. Dealers are required to bring a minimum of one (1) Electric vehicle, one (1) Hybrid vehicle, and one (1) ICE vehicle. It is up to the dealership to determine which vehicles will be featured at the event. Most Ford vehicles are eligible for test-drives; however, test-drives completed in a Lincoln vehicle are not eligible for a donation."
    },
    {
        category: 'general',
        question: "Can a Drive 4 UR Community event benefit more than one club or activity?",
        answer: "A dealer may support up to two (2) schools or work with one (1) community organization but not both. Program HQ will send only one check per event. It is the organization’s responsibility to distribute the funds among clubs or activities."
    },
    {
        category: 'general',
        question: "Do I have to have an affiliation with the organization(s) or school(s) to participate?",
        answer: "No. Anyone who is 18 years or older with a valid driver's license and valid automobile insurance can participate on event day. And you can feel good doing it knowing that you are helping the community!"
    },
    {
        category: 'general',
        question: "Can we supplement our event fundraisers?",
        answer: "Yes! Feel free to attract people to your fundraisers in whatever way you like. Bake sales, talent shows, car washes, sporting events, open houses ─ whatever drives people your way! Supplemental activities are the responsibility of the dealer and/or school/organization."
    },
    {
        category: 'dealer',
        question: "I'm interested in hosting a Drive 4 UR Community event, but I have more than one school in my market area. Can I work with multiple schools?",
        answer: "Yes! You may work with up to two (2) schools. You must indicate this when you request your event. Drivers will indicate which school they are supporting when they register to test-drive. Dealerships are responsible for adhering to all local and state laws regarding off-site test-drives and vehicle displays. Dealers are responsible for ensuring that all events are located within their PMA/Locality Zone and should double-check with their Network Development Specialist if unsure. The total donation earned will have to be split among the participating schools and will max out at $6,000. Program HQ will send only one check to the dealership made out to the lead school. It is that school’s responsibility to distribute the funds to the other school. To help with this, once your event is complete, your Event Portal will show the school participation percentage that you can share with the lead school. The schools must work together to conduct one event at one location on the same day."
    },
    {
        category: 'dealer',
        question: "What vehicles should I bring to my event?",
        answer: "You should plan to bring five to seven vehicles to the site. Dealers are required to bring a minimum of one (1) Electric vehicle, one (1) Hybrid vehicle, and one (1) ICE vehicle."
    },
    {
        category: 'dealer',
        question: "What happens if I want to host my event outside of my dealership’s PMA?",
        answer: "Your dealership may be asked to get written permission from the dealer whose PMA/locality the event is in. If you cannot obtain written permission to host your event in another dealer’s PMA/locality, you can relocate your event to a location within your dealership’s PMA/Locality Zone or move the event to the dealership site. Dealerships are responsible for adhering to all local and state laws regarding off-site test-drives and vehicle displays."
    },
    {
        category: 'dealer',
        question: "How should I promote my event?",
        answer: "Be creative and start early! Post event flyers and posters in high-traffic areas around the dealership and school or organization to generate awareness. With permission of local businesses, spread the word to the community via their establishments. Send invitations and announcements to community leaders. Use social media to spread the word! Digital graphics are provided to you in your event portal and can be shared with the school. Posters and other promotional materials are provided in your event kit. Leverage the school or organization’s channels and word of mouth; encourage them to invite their closest supporters to support the event."
    },
    {
        category: 'dealer',
        question: "How long does it take for schools or organizations to receive their check?",
        answer: "The groups can expect to receive their check approximately three to four weeks from the date Program HQ receives your completed waiver/survey forms. Please note that we cannot send checks to P.O. boxes, and that checks cannot be made out to the dealership or an individual."
    },
    {
        category: 'dealer',
        question: "How many events can I host in 2025?",
        answer: "Dealers may host only one event in 2025."
    },
    {
        category: 'electronic',
        question: "Is Wi-Fi® required at my event?",
        answer: "A Wi-Fi® connection is not required to hold the event. It is only required if internet connection through cell phone data is not available. Participants can use their smartphones to access the survey, and the dealership can use their smartphones to check consumers in and out. Dealers’ smartphones can also function as a hot spot for laptops and tablets, should the dealership choose to use them. The dealership will need to work with the organization(s)/school(s) to best prepare for the event. If you feel they cannot access wireless or technology at the event location, you are permitted to make an event location change by contacting Program HQ, but you will still be required to utilize electronic waivers at this event."
    },
    {
        category: 'electronic',
        question: "Do we need to provide/purchase iPads/laptops for participants to access the survey?",
        answer: "No. Participants can complete the pre-drive and post-drive surveys using their personal smartphones."
    },
    {
        category: 'electronic',
        question: "Will I be receiving devices for electronic waiver events from Ford?",
        answer: "No, Ford will not be supplying devices for these events. Dealerships will need a device to check participants in and out of the event (can be a smartphone, tablet, or laptop) and participants can use their own personal devices to fill out the waivers. Dealerships can also supply their own laptops or tablets at the event for participants to fill out the surveys (if Wi-Fi® is available) instead of consumers using their own personal devices."
    },
    {
        category: 'electronic',
        question: "If participants use their own personal devices, does the dealership need a device?",
        answer: "Dealership staff/volunteers need a device to check participants in and out of the event. This can be a smartphone if no other devices are available. Dealership staff/volunteers can use multiple devices simultaneously to check participants in and out."
    },
    {
        category: 'electronic',
        question: "What if participants forget to take the post-event survey at home?",
        answer: "Participants will be sent a reminder text message/email four hours after the post-drive survey is originally sent. They have until 11:59 p.m. of the day following the event to complete the post-drive survey."
    },
    {
        category: 'electronic',
        question: "What if participants don't want to give out their phone or email?",
        answer: "Phone number OR email is required for this process in order to submit the waivers properly. This information is ONLY used to send the test-drive survey to complete. Participants will not receive any other information unless they opt-in to receive Ford communication."
    },
    {
        category: 'electronic',
        question: "Where can I find training on the electronic waiver process?",
        answer: "Starting July 1, the training video can be found on your Dealer Event Portal by clicking the “Training” tab. A printable version of this training video can be found by clicking the “Info” tab."
    },
    {
        category: 'electronic',
        question: "Who can I contact if I'm having technical issues with data capture on the day of my event?",
        answer: "Should you experience an issue with electronic data capture on the day of your event, you can contact Latitude Digital at (313) 486-5963 to troubleshoot through your issues. This is ONLY for technical issues on the day of your event. The support line will not be staffed/monitored on non-event days. You are responsible for ensuring your event location has adequate connectivity (cellular/Wi-Fi®) available. If you have general D4URC program or event questions, you will need to email Program HQ at hello@d4urc.com or contact your area representative."
    }
];