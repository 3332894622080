import React, { useEffect, useState } from "react";

import { useLocalization } from "@progress/kendo-react-intl";
import { useHistory } from "react-router-dom";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Error } from "@progress/kendo-react-labels";
import {
  emailValidator,
  requiredValidator,
  passwordValidator,
  passwordMatchValidator,
  partsCodeValidator,
  address1Validator,
  cityValidator,
  zipValidator,
  phoneValidator,
} from "../validators";
import { states } from "../resources/states";
import "../styles/_forms.scss";

import { AppContext } from "./../AppContext";
import { useStores } from "src/stores";
import { useServices } from "src/services";
import { Input } from "src/components/form/Input";
import logo from "../assets/images/Ford_D4URC_Blue_Screen_RGB_v2.png";

// @ts-ignore
import PrivacyBanner from "react-ford-ccpa-banner";
import { DropDownList } from "src/components/form/DropDownList";
import { MaskedTextBox } from "src/components/form/MaskedTextBox";
import { Loader } from "@progress/kendo-react-indicators";

const SignUpScreen = () => {
  const contextFormValues = React.useContext(AppContext);
  const localizationService = useLocalization();
  const history = useHistory();
  const { language } = localizationService;

  const [loginError, setLoginError] = useState<string>();
  const [partsCodeError, setPartsCodeError] = useState<string>();
  const { login } = useStores();
  const { d4ur } = useServices();
  const [isLoadingDealerInfo, setIsLoadingDealerInfo] =
    useState<boolean>(false);
  const [lastCheckedPartsCode, setLastCheckedPartsCode] = useState<string>("");

  useEffect(() => {
    if (login.dealer) {
      console.log("[SignUpScreen]", "I think we are already logged in");
      d4ur.getAccount().then(() => {
        history.push("/events");
      });
    }
    // eslint-disable-next-line
  }, []);

  const handlePartsCodeChange = async (event: any, formRenderProps: any) => {
    const partsCode = event.value;

    // Only proceed if we have a complete 5-digit parts code and it's different from the last one we checked
    if (
      partsCode &&
      partsCode.length === 5 &&
      partsCode !== lastCheckedPartsCode
    ) {
      // Check if the parts code is valid using the validator
      const validationResult = partsCodeValidator(partsCode);

      if (!validationResult) {
        try {
          setIsLoadingDealerInfo(true);
          setPartsCodeError(undefined);
          setLastCheckedPartsCode(partsCode); // Remember this code to avoid duplicate requests

          const response: any = await d4ur.getDealerInfo(partsCode);
          console.log("Dealer info response:", response);

          if (response.result && response.result.dealer_info) {
            const dealerInfo = response.result.dealer_info;
            console.log("Dealer info:", dealerInfo);

            // Update form fields directly using formRenderProps.onChange
            formRenderProps.onChange("dealer_name", {
              value: dealerInfo.dealer_name || "",
            });
            formRenderProps.onChange("address", {
              value: dealerInfo.address || "",
            });
            formRenderProps.onChange("address2", {
              value: dealerInfo.address2 || "",
            });
            formRenderProps.onChange("city", { value: dealerInfo.city || "" });
            formRenderProps.onChange("state", {
              value: dealerInfo.state || "",
            });
            formRenderProps.onChange("zip", { value: dealerInfo.zip || "" });
          } else if (response.result && response.result.errors) {
            setPartsCodeError(response.result.errors.join("\n"));
          } else {
            setPartsCodeError(
              localizationService.toLanguageString(
                "custom.noDataForPartsCode",
                "No dealer information found for this parts code."
              )
            );
          }
        } catch (error) {
          console.error("[SignUpScreen]", "Error fetching dealer info:", error);
          setPartsCodeError(
            localizationService.toLanguageString(
              "custom.errorFetchingDealerInfo",
              "Error fetching dealer information. Please try again."
            )
          );
        } finally {
          setIsLoadingDealerInfo(false);
        }
      }
    }
  };

  const onSubmit = React.useCallback(
    async (dataItem) => {
      const dealerSignup: Dealer = dataItem;

      login.setLoginEmail(dealerSignup.contact_email);
      const call: any = await d4ur.doSignup(dealerSignup, dataItem.password);
      if (call.result.errors) {
        console.log("[SignUpScreen]", "Signup Error");
        setLoginError(call.result.errors.join("\n"));
      }
      if (call.result.dealer && call.result.token) {
        console.log("[SignUpScreen]", "created & logged in");
        login.setDealerToken(call.result.token);
        login.setDealer(call.result.dealer as Dealer);
        history.push("/events");
      }
    },
    [login, history, d4ur]
  );

  return (
    <div id="Login" className="home-page main-content">
      <div className="signup-content">
        <div className={"logo_container"}>
          <img
            src={logo}
            alt={"Ford Drive 4 UR School Logo"}
            className={"logo"}
            onClick={() => {
              history.push("/");
            }}
          />
        </div>
        <div className="form-container">
          <div className="card-component">
            <h1 className="title">
              {localizationService.toLanguageString("custom.signUp", "Sign Up")}
            </h1>
            <p className="card-text">
              {localizationService.toLanguageString(
                "custom.signUpText",
                "Sign Up Text"
              )}
            </p>
            <Form
              onSubmit={onSubmit}
              initialValues={{
                ...contextFormValues,
                contact_email: login.loginEmail,
              }}
              render={(formRenderProps) => (
                <FormElement horizontal={true} style={{ maxWidth: '100%' }}>
                  {loginError && <Error>{loginError}</Error>}
                  <Field
                    id={"parts_code"}
                    name={"parts_code"}
                    label={`${localizationService.toLanguageString(
                      "custom.sales_code",
                      "sales_code"
                    )}*`}
                    validator={partsCodeValidator}
                    hint={
                      "The Dealer Sales Code starts with an L-. Please enter the 5 digits that follow the L."
                    }
                    required={true}
                    mask="00000"
                    component={MaskedTextBox}
                    onChange={(event) =>
                      handlePartsCodeChange(event, formRenderProps)
                    }
                  />
                  {isLoadingDealerInfo && (
                    <div className="dealer-info-loading">
                      <Loader type="infinite-spinner" size="large" />
                      <span style={{ marginLeft: "10px" }}>
                        Loading dealer information...
                      </span>
                    </div>
                  )}
                  {partsCodeError && <Error>{partsCodeError}</Error>}
                  <Field
                    id={"dealer_name"}
                    name={"dealer_name"}
                    maxLength={25}
                    label={`${localizationService.toLanguageString(
                      "custom.dealer_name",
                      "dealer_name"
                    )}*`}
                    validator={requiredValidator}
                    required={true}
                    component={Input}
                    hint={
                      "Max Length: 25 characters. Please do not type in all caps."
                    }
                  />
                  <Field
                    id={"address"}
                    name={"address"}
                    maxLength={45}
                    hint={"Please Do NOT Provide a PO Box"}
                    label={`${localizationService.toLanguageString(
                      "custom.dealer_address",
                      "address"
                    )}*`}
                    validator={address1Validator}
                    required={true}
                    component={Input}
                  />
                  <Field
                    id={"address2"}
                    name={"address2"}
                    maxLength={45}
                    label={localizationService.toLanguageString(
                      "custom.address2",
                      "address2"
                    )}
                    component={Input}
                  />

                  <div className="location-fields-grid">
                    <div className="location-column">
                      <Field
                        id={"city"}
                        name={"city"}
                        maxLength={45}
                        label={`${localizationService.toLanguageString(
                          "custom.city",
                          "city"
                        )}*`}
                        validator={cityValidator}
                        required={true}
                        component={Input}
                      />
                    </div>
                    <div className="location-column">
                      <Field
                        component={DropDownList}
                        id={"state"}
                        name={"state"}
                        label={`${localizationService.toLanguageString(
                          "custom.state",
                          "state"
                        )}*`}
                        data={states}
                        validator={requiredValidator}
                        required={true}
                      />
                    </div>
                    <div className="location-column">
                      <Field
                        id={"zip"}
                        name={"zip"}
                        label={`${localizationService.toLanguageString(
                          "custom.zip",
                          "zip"
                        )}*`}
                        validator={zipValidator}
                        required={true}
                        mask="00000"
                        component={MaskedTextBox}
                      />
                    </div>
                  </div>

                  <div className="contact-fields-grid">
                    <div className="contact-column">
                      <Field
                        id={"primary_contact"}
                        name={"primary_contact"}
                        maxLength={45}
                        label={`${localizationService.toLanguageString(
                          "custom.primary_contact",
                          "primary_contact"
                        )}*`}
                        validator={requiredValidator}
                        required={true}
                        component={Input}
                        hint={
                          "This contact should be present on-site during the event."
                        }
                      />
                    </div>
                    <div className="contact-column">
                      <Field
                        id={"contact_phone"}
                        name={"contact_phone"}
                        label={`${localizationService.toLanguageString(
                          "custom.contact_phone",
                          "contact_phone"
                        )}*`}
                        validator={phoneValidator}
                        required={true}
                        mask="000-000-0000"
                        placeholder={"e.g.: 000-000-0000"}
                        component={MaskedTextBox}
                      />
                    </div>
                    <div className="contact-column">
                      <Field
                        id={"contact_email"}
                        name={"contact_email"}
                        type={"email"}
                        autoComplete="username"
                        placeholder={"e.g.: peter@gmail.com"}
                        label={`${localizationService.toLanguageString(
                          "custom.dealerContactEmail",
                          "Email"
                        )}*`}
                        validator={emailValidator}
                        required={true}
                        component={Input}
                      />
                    </div>
                  </div>

                  <p style={{ fontSize: '13px', marginTop: '0px', marginBottom: '20px'}}>
                    The additional contacts listed below should be up-to-date on
                    all event details and will receive important communications
                    regarding the event.
                  </p>

                  <div className="contact-fields-grid">
                    <div className="contact-column">
                      <Field
                        id={"secondary_contact"}
                        name={"secondary_contact"}
                        maxLength={45}
                        label={`${localizationService.toLanguageString(
                          "custom.secondary_contact",
                          "Secondary Dealer Contact Name"
                        )}*`}
                        validator={requiredValidator}
                        required={true}
                        component={Input}
                      />
                    </div>
                    <div className="contact-column">
                      <Field
                        id={"secondary_contact_phone"}
                        name={"secondary_contact_phone"}
                        label={`${localizationService.toLanguageString(
                          "custom.secondary_contact_phone",
                          "Secondary Dealer Contact Phone"
                        )}*`}
                        validator={phoneValidator}
                        required={true}
                        mask="000-000-0000"
                        placeholder={"e.g.: 000-000-0000"}
                        component={MaskedTextBox}
                      />
                    </div>
                    <div className="contact-column">
                      <Field
                        id={"secondary_contact_email"}
                        name={"secondary_contact_email"}
                        type={"email"}
                        placeholder={"e.g.: contact2@gmail.com"}
                        label={`${localizationService.toLanguageString(
                          "custom.secondary_contact_email",
                          "Secondary Dealer Contact Email"
                        )}*`}
                        validator={emailValidator}
                        required={true}
                        component={Input}
                      />
                    </div>
                  </div>

                  <div className="contact-fields-grid">
                    <div className="contact-column">
                      <Field
                        id={"tertiary_contact"}
                        name={"tertiary_contact"}
                        maxLength={45}
                        label={`${localizationService.toLanguageString(
                          "custom.tertiary_contact",
                          "Additional Dealer Contact Name"
                        )}*`}
                        validator={requiredValidator}
                        required={true}
                        component={Input}
                      />
                    </div>
                    <div className="contact-column">
                      <Field
                        id={"tertiary_contact_phone"}
                        name={"tertiary_contact_phone"}
                        label={`${localizationService.toLanguageString(
                          "custom.tertiary_contact_phone",
                          "Additional Dealer Contact Phone"
                        )}*`}
                        validator={phoneValidator}
                        required={true}
                        mask="000-000-0000"
                        placeholder={"e.g.: 000-000-0000"}
                        component={MaskedTextBox}
                      />
                    </div>
                    <div className="contact-column">
                      <Field
                        id={"tertiary_contact_email"}
                        name={"tertiary_contact_email"}
                        type={"email"}
                        placeholder={"e.g.: contact3@gmail.com"}
                        label={`${localizationService.toLanguageString(
                          "custom.tertiary_contact_email",
                          "Additional Dealer Contact Email"
                        )}*`}
                        validator={emailValidator}
                        required={true}
                        component={Input}
                      />
                    </div>
                  </div>


                  <Field
                    id={"password"}
                    name={"password"}
                    autoComplete="new-password"
                    type={"password"}
                    label={`${localizationService.toLanguageString(
                      "custom.password",
                      "Password"
                    )}*`}
                    validator={passwordValidator}
                    required={true}
                    component={Input}
                  />
                  <Field
                    id={"confirm_password"}
                    name={"confirm_password"}
                    type={"password"}
                    autoComplete="new-password"
                    label={`${localizationService.toLanguageString(
                      "custom.confirmPassword",
                      "Confirm Password"
                    )}*`}
                    validator={passwordMatchValidator}
                    required={true}
                    component={Input}
                  />
                  <div className="create_btn_container">
                    <Button
                      primary={true}
                      type={"submit"}
                      disabled={!formRenderProps.allowSubmit}
                      className="standard_btn"
                    >
                      {localizationService.toLanguageString(
                        "custom.signUp",
                        "Sign Up"
                      )}
                    </Button>
                  </div>
                </FormElement>
              )}
            />
            <div className="login_container log_in">
              <p className="login_text">Already have an account?</p>
              <button
                className="login_btn"
                onClick={() => {
                  history.push("/login");
                }}
              >
                Log In
              </button>
            </div>
          </div>
        </div>
      </div>
      <PrivacyBanner
        containerStyle={{ padding: "10px 0" }}
        privacyContainerStyle={{
          padding: "10px 0",
          width: "60%",
          marginLeft: "20%",
        }}
        showPrivacy={true}
        language={language}
        team={"ford"}
        imgStyle={{ paddingLeft: "30px" }}
        otherLinks={[
          { text: "CONTACT US", url: "/contact", target: "_blank" },
          {
            text: "PROGRAM TERMS AND CONDITIONS",
            url: "/terms-eligibility",
            target: "_blank",
          },
          { text: "PROGRAM RESOURCES ", url: "/resources", target: "_blank" },
        ]}
      />
    </div>
  );
};

export default SignUpScreen;
