const emailRegex = new RegExp(
  '^(([^<>()\\[\\]\\\\.,;:`\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:`\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.(?!con))+[a-zA-Z]{2,}))$'
);
const phoneRegex = new RegExp(/^[0-9 ()+-]+$/);
const taxIdRegex = new RegExp(
  /^([07][1-7]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|9[0-589])-?\d{7}/
);
const address1Regex = new RegExp(/^\S[a-zA-Z0-9\s,'-/]*$/);
const partsCodeRegex = new RegExp(/^[0-9]+$/);
const cityRegex = new RegExp(/^\S+[a-zA-Z ,.'-]+$/);
const stateRegex = new RegExp(/^[a-zA-Z]{2}$/);
const zipRegex = new RegExp(
  /(^\d{5}(-\d{4})?$)|(^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Za-z]{1} *\d{1}[A-Za-z]{1}\d{1}$)/
);
const htmlRegex = new RegExp(/<[^>]*>/g);
const nameRegex = new RegExp(/^\S+[a-zA-Z ,.'-]+$/);
const requiredMessage = "This field is required";
const poBoxRegex = new RegExp(/\b[P|p]*(OST|ost)*\.*\s*[O|o|0]*(ffice|FFICE)*\.*\s*[B|b][O|o|0][X|x]\b|\b[P|p][O|o|0]\s*[B|b][O|o|0][X|x]\b/);

export const emailValidator = (value) =>
  emailRegex.test(value) ? "" : "You have entered an invalid email address!";
export const partsCodeValidator = (value) =>
  value && partsCodeRegex.test(value) ? "" : "Please enter valid sales code.";
export const address1Validator = (value) => {
    if (!value) return "This field is required";
    if (poBoxRegex.test(value)) return "P.O. Box addresses are not accepted.";
    return address1Regex.test(value) ? "" : "Please enter valid address.";
};
export const cityValidator = (value) =>
  value && cityRegex.test(value) ? "" : "Please enter valid city.";
export const stateValidator = (value) =>
  value && stateRegex.test(value) ? "" : "Please enter valid state.";
export const zipValidator = (value) =>
  value && zipRegex.test(value) ? "" : "Please enter valid zip code.";
export const taxIdValidator = (value) =>
  value && taxIdRegex.test(value) ? "" : "Please enter valid tax id.";
export const nameValidator = (value) =>
  value && nameRegex.test(value) ? "" : "Please enter valid name.";
export const passwordValidator = (value, valueGetter) =>
  value && value.length > 5 ? "" : "Password must be at least six characters";
export const passwordMatchValidator = (value, valueGetter) =>
  value === valueGetter("password") ? "" : "Passwords must match";
export const newPasswordValidator = (value, valueGetter) =>
  (value && value.length > 5) || !value || !value.length
    ? ""
    : "Password must be at least six characters";
export const requiredValidator = (value) => (value ? "" : requiredMessage);
export const phoneValidator = (value) =>
  value && phoneRegex.test(value) ? "" : "Please enter valid phone number.";
export const biographyValidator = (value) =>
  value && value.replace(htmlRegex, "").length >= 50
    ? ""
    : "Biography must be at least 50 characters long.";
