import { default as React } from "react";
// @ts-ignore
import PrivacyBanner from 'react-ford-ccpa-banner';
import { useLocalization } from "@progress/kendo-react-intl";
import logo from "../assets/images/Ford_D4URC_Blue_Screen_RGB_v2.png";
import { useHistory } from "react-router-dom";
const TermsScreen = () => {
    const localizationService = useLocalization();
    const { language } = localizationService;
    let history = useHistory();

    return (
        <div id="Login" className="guidelines-page">
            <img src={logo} alt={'Ford Drive 4 UR Community Logo'} className={'logo'} onClick={() => {
                history.push("/events");
            }} />
            <div className="content">
                <h1 className="card-title">Program Terms and Conditions</h1>
                <p>In submitting my Drive 4 UR Community (D4URC) event request, I recognize that if approved to host a D4URC event, I am responsible for understanding and agreeing to the following:</p>
                <ol>
                    <li>I understand that in order to be eligible to host a 2025 Ford D4URC event, the Ford Dealer must be in good standing or was otherwise part of the appropriate Ford structure, and the benefiting organization must be vetted and approved by D4URC Program HQ.</li>
                    <li>I accept that I cannot host more than one (1) fully funded Ford D4URC event in 2025.</li>
                    <li>My event must be held on a single day and at one location only.</li>
                    <li>I will be sent a confirmation email after I submit my D4URC event enrollment request on the program site.</li>
                    <li>Dealers are required to bring a minimum of one (1) Electric vehicle, one (1) Hybrid vehicle, and one (1) ICE vehicle.</li>
                    <li>I understand that for each test-drive, each waiver and survey must be submitted digitally through my event’s unique registration link. Paper waivers will not be available for the 2025 D4URC events.</li>
                    <li>My store and I are responsible for adhering to all local and state laws regarding test-drives.</li>
                    <li>Insurance, if necessary, is the responsibility of the dealership.</li>
                    <li>My event location will be within my Ford Primary Market Area, and I will contact my Ford Specialist with any questions regarding my Ford Dealer PMA.</li>
                    <li>My event cannot be hosted on the same day or in conjunction with another Ford or Ford Motor Company-sponsored event.</li>
                    <li>I understand that Ford will only distribute donation funds to the chosen and approved organization after an event has been completed and test-drives have been validated*. I further understand that Ford will not distribute donation funds if an event does not take place and is canceled for any reason.</li>
                    <li>I understand that I may not work with more than two (2) schools per event or one organization.</li>
                    <li>If I am working with two (2) schools, I understand that my dealership is responsible for coordinating with the lead school receiving the check to ensure funds are distributed to both schools.</li>
                    <li>If I want to cancel my event for any reason, I am responsible for contacting Program HQ at <a href="mailto:hello@d4urc.com">hello@d4urc.com</a> to communicate my event cancellation.</li>
                    <li>I understand that the maximum donation for an event is $6,000. NO PURCHASE IS NECESSARY. Donation of $20 per valid test-drive, up to 300 test-drives, for a total donation of $6,000 per event. Valid test drives are determined by the driver being 18 years or older, possessing a valid driver’s license and automobile insurance, completing both the pre-survey/waiver and post-survey, and taking a test drive on the pre-established route. Limit one (1) donation per household.</li>
                    <li>I understand that if the benefiting organization does not cash their donation check within 60 days of issue, Ford reserves the right to donate the funds to another organization of their choice.</li>
                    <li>Test-drivers must be 18 years of age or older with a valid driver’s license and valid automobile insurance.</li>
                    <li>I understand that Ford will provide various promotional materials free of charge. This could include customizable, digital promotional materials for use by the dealer and organization to promote registration.</li>
                    <li>I accept that select events, as determined by Program HQ, may receive an influencer or content capturer to attend my event.</li>
                    <li>Dealerships are responsible for adhering to all local and state laws regarding off-site test-drives and vehicle displays.</li>
                </ol>
                <p>Should you have questions, please contact Drive 4 UR Community Program HQ: <a href="mailto:hello@d4urc.com">hello@d4urc.com</a>.</p>
                <p>
                * NO PURCHASE NECESSARY. Must be 18 years of age or older with a valid driver's license and valid automobile insurance. Donation of $20 per valid test-drive, up to 300 test-drives, for a total maximum donation of $6,000 per event. Limit one (1) donation per household. If the benefitting organization does not cash their donation check within 60 days of issue, Ford reserves the right to donate the funds to another organization of their choice.
                </p>
            </div>
            <PrivacyBanner
                containerStyle={{ padding: '10px 0 0', width: '100%' }}
                privacyContainerStyle={{ padding: '10px 0', width: "60%", marginLeft: '20%' }}
                showPrivacy={true}
                language={language}
                team={'ford'}
                imgStyle={{ paddingLeft: '30px' }}
                otherLinks={[
                    { text: "CONTACT US", url: "/contact", target: '_blank' },
                    { text: "PROGRAM TERMS AND CONDITIONS", url: "/terms-eligibility", target: '_blank'},
                    { text: "PROGRAM RESOURCES ", url: "/resources", target: '_blank' },
                ]}
            />
        </div>
    );
};

export default TermsScreen;
