import { default as React } from "react";
// @ts-ignore
import PrivacyBanner from 'react-ford-ccpa-banner';
import { useLocalization } from "@progress/kendo-react-intl";
import logo from "../../assets/images/Ford_D4URC_Blue_Screen_RGB_v2.png";
const ElementsScreen = () => {
    const localizationService = useLocalization();
    const { language } = localizationService;

    return (
        <div id="Profile" className="guidelines-page content">
            <img src={logo} alt={'Ford Drive 4 UR Community Logo'} className={'logo'} />
            <div className="card-container">
                <div className="card-component">
                <h2>Required Event Elements</h2>
                <p>Each Ford Drive 4 UR Community event must include the following elements:</p>
                <ul>
                    <li>
                    Usage of the program name and logo in promotional materials and at the event
                        <ul>
                            <li>
                                Program name: Ford Drive 4 UR Community
                            </li>
                        </ul>
                    </li>
                    <li>
                        Data capture
                        <ul>
                            <li>
                            Both the registration survey and the post-event survey must be filled out for the donation to be valid
                            </li>
                        </ul>
                    </li>
                    <li>
                        Test-drives
                        <ul>
                            <li>
                            A test-drive must occur for a donation to be made to the benefitting organization 
                            </li>
                        </ul>
                    </li>
                </ul>
                </div>
            </div>
            <PrivacyBanner
                containerStyle={{ padding: '10px 0 0', width: '100%' }}
                privacyContainerStyle={{ padding: '10px 0', width: "60%", marginLeft: '20%' }}
                showPrivacy={true}
                language={language}
                team={'ford'}
                imgStyle={{ paddingLeft: '30px' }}
                otherLinks={[
                    { text: "CONTACT US", url: "/contact", target: '_blank' },
                    { text: "PROGRAM TERMS AND CONDITIONS", url: "/terms-eligibility", target: '_blank'},
                    { text: "PROGRAM RESOURCES ", url: "/resources", target: '_blank' },
                ]}
            />
        </div>
    );
};

export default ElementsScreen;
